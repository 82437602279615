
import { mixins } from 'vue-class-component';
import TasqListMixin from '@/components/tasqsCommon/TasqListMixin';
import { Component } from 'vue-property-decorator';
import GenericMixin from '@/lib/mixins/GenericMixin';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import isOnline from 'is-online';

@Component({
  components: {
    SwipeOut,
    SwipeList,
  },
})
export default class TasqsVerticalList extends mixins(
  TasqListMixin,
  GenericMixin,
) {
  dataFetchInterval: any = null;

  screenWidthLarge = window.innerWidth > 1400

  async mounted() {
    await this.prepareListView();
    this.completedVisible = false;
    await this.setupDataFetching();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  }

  async onResize() {
    this.screenWidthLarge = window.innerWidth > 1400;
  }

  async setupDataFetching() {
     // Fetch data every hour
      this.dataFetchInterval = setInterval(async () => {
        const isOnlineApp = await isOnline();

       if (isOnlineApp) {
        tasqsListModule.setIsRefetchingData(true);
        console.log('refreshing the list');
        await this.prepareListView();
        tasqsListModule.setIsRefetchingData(true);
       }
      }, 7 * 60 * 1000); // 1 hour in milliseconds
    }

    beforeDestroy() {
    clearInterval(this.dataFetchInterval);
    window.removeEventListener('resize', this.onResize);
  }

  itemClick() {
    tasqsListModule.setShowTasqDetailsMobile(true);
  }

  scrollToListTop() {
    const divElement: any = document.getElementById('infinite-list')!;
    if (divElement) {
      divElement.scrollTo(0, 0);
    }
  }
}
